import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  score: {
    e: 0,
    i: 0,
    s: 0,
    n: 0,
    f: 0,
    t: 0,
    j: 0,
    p: 0,
  },
  quizTransition: false,
  quizIndex: 0,
  quiz: [
    {
      q: "더 좋아하는 영화 장르는",
      a: [
        {
          target: "s",
          score: 1,
          text: "로맨스, 다큐멘터리",
        },
        {
          target: "n",
          score: 1,
          text: "액션, SF",
        },
      ],
    },
    {
      q: "내가 즐거운 순간은",
      a: [
        {
          target: "e",
          score: 1,
          text: "친구들과 함께 즐기는 모임",
        },
        {
          target: "i",
          score: 1,
          text: "혼자 보내는 평온한 시간",
        },
      ],
    },
    {
      q: "미술관 데이트는",
      a: [
        {
          target: "s",
          score: 1,
          text: "그다지 좋아하지 않는다",
        },
        {
          target: "n",
          score: 1,
          text: "좋아하는 편이다",
        },
      ],
    },
    {
      q: "사람들과 이야기할 때",
      a: [
        {
          target: "e",
          score: 1,
          text: "즐겁게 시간을 보낸다",
        },
        {
          target: "i",
          score: 1,
          text: "오래하면 진이 빠진다",
        },
      ],
    },
    {
      q: "데이트 코스는",
      a: [
        {
          target: "j",
          score: 1,
          text: "미리 코스를 알아봐둔다",
        },
        {
          target: "p",
          score: 1,
          text: "그때그때 느낌 가는대로",
        },
      ],
    },
    {
      q: "잡담을 좋아하는가",
      a: [
        {
          target: "n",
          score: 1,
          text: "조금 깊은 이야기를 선호한다",
        },
        {
          target: "s",
          score: 1,
          text: "매우 좋아한다",
        },
      ],
    },
    {
      q: "애인이 문제에 직면했을때",
      a: [
        {
          target: "f",
          score: 1,
          text: "감정에 공감한다",
        },
        {
          target: "t",
          score: 1,
          text: "해결책을 제안한다",
        },
      ],
    },
    {
      q: "선호하는 유튜브 콘텐츠는",
      a: [
        {
          target: "t",
          score: 1,
          text: "새로운 지식, 뉴스, 요리법",
        },
        {
          target: "f",
          score: 1,
          text: "드라마, 뮤직비디오, 예능",
        },
      ],
    },
    {
      q: "다른 사람들에게 나는",
      a: [
        {
          target: "p",
          score: 1,
          text: "융통성이 있는 사람",
        },
        {
          target: "j",
          score: 1,
          text: "꼼꼼하고, 철저한 사람",
        },
      ],
    },
    {
      q: "사회적인 문제에 대해",
      a: [
        {
          target: "t",
          score: 1,
          text: "깊게 생각하는 편이다",
        },
        {
          target: "f",
          score: 1,
          text: "그다지 생각하지 않는 편이다",
        },
      ],
    },
    {
      q: "데이트 하는 중 거리에서 친구 커플을 만나게 됐다",
      a: [
        {
          target: "e",
          score: 1,
          text: '"같이 놀까?" 친구 커플과 같이 시간을 보낸다',
        },
        {
          target: "i",
          score: 1,
          text: "오늘은 단 둘이만 있으면 좋겠어",
        },
      ],
    },
    {
      q: "완벽주의 성향이 있는가",
      a: [
        {
          target: "j",
          score: 1,
          text: "어느정도 있다",
        },
        {
          target: "p",
          score: 1,
          text: "그렇지 않다",
        },
      ],
    },
  ],
  result: {
    isfp: {
      title: "천상 낙관주의자",
      text:
        "리드? 해줘! 네가 나를 데리고 가면 나는 어디든 좋아. 항시 낙관적인 자세를 유지하지만 주관이 확실해 첫인상이 에러라면, 결혼까지는 생각하지 않는 게 좋을걸? 연애에 신중한 편이지만 저돌적으로 다가오는 상대에겐 맥을 못 추는 편. 싫은 사람은 싫지만 거절을 단호하게 하지 못해 헷갈림 주의보 발령! 이것도 좋고 저것도 좋아 갈팡질팡해서 결혼도 상대방이 적극적으로 리드해서 하는 경향이 있습니다. 항상 결혼은 신중하게!라는 자세가 필요할 것으로 보입니다.",
      tag: "#언젠간생기겠지 #나정도면 #댕댕미",
      status: {
        charm: 5,
        romance: 3,
        sexual: 4,
        drive: 2,
        energy: 2,
      },
      good: "enfj",
      bad: "infp",
    },
    isfj: {
      title: "순정만화 주인공",
      text:
        "내 친구들도 연애 잘만 하는데 나의 꿈같은 애인님은 언제 찾아오는 걸까? 하지만 막상 결혼에 골인한다면 가정과 애인에게 헌신하는 스타일로 상대방에게 원만히 맞춰주는 편입니다. 항시 로맨틱함을 꿈꾸기 때문에 상대방에게 배려하는 모습도 많이 보여주지만 자신을 온전히 파악하고 이해해 주길 원하죠. 결혼에 골인하는 과정에서 탄탄대로로 흘러가도 나중에 관계가 지루해진다면 다른 데로 눈이 돌아갈 수 있으니까 항상 주의하는 자세 필요!",
      tag: "#청순가련 #이세계로떠나자 #자만추",
      status: {
        charm: 3,
        romance: 4,
        sexual: 3,
        drive: 2,
        energy: 3,
      },
      good: "enfp",
      bad: "isfp",
    },
    istp: {
      title: "나하고만 놀아줘",
      text:
        "겉으로는 쿨하고 시크해 보여서 다가가기 어렵지만 자신의 바운더리 안에 들어오면 한없이 약해지는 당신, 결혼을 한다면 애인에게 한없이 기대는 자신을 발견할 수도 있습니다. 걱정이 많은 편이라 좋아하는 상대가 있어도 이 사람과의 결혼까지 상상해보곤 하지만 곧 자신의 망상에서 끝내는 편이라 실행에 옮기지는 않죠. 평소 모습과 정 반대의 모습을 연애나 결혼에서 보여주다 보니 어디로 튈지 몰라 결혼은 조금 미루는 게 좋을 수도?",
      tag: "#때쟁이 #술자리메이커 #자유가최고",
      status: {
        charm: 5,
        romance: 2,
        sexual: 4,
        drive: 4,
        energy: 4,
      },
      good: "estj",
      bad: "enfj",
    },
    istj: {
      title: "꼼꼼하고 현실적인",
      text:
        "썸탈때나 연애할 때는 꽤나 지루해 보였던 당신, 연애에 관심 없다는 말도 많이 들었었죠? 내가 만약 결혼한다면 자만추는 안될 거야! 나의 일이 더 우선이니까! 크게 감정을 표현하는 편도 아니지만 만약 결혼에 골인한다면 상대방이 잠수 이별을 하지 않는 이상 200% 안정적인 연애 가능한 낙관 주의자입니다. 상대방에게 배울 점이 많거나 똑똑한 사람이라면 무한대로 존경을 표하는 경향도 있습니다. 이 사람과의 결혼, 꽤나 좋을지도?",
      tag: "#똑똑 #일단고민해보자 #결혼은현실이야",
      status: {
        charm: 2,
        romance: 3,
        sexual: 4,
        drive: 3,
        energy: 3,
      },
      good: "enfp",
      bad: "estj",
    },
    infp: {
      title: "멜로소설 주인공",
      text:
        "“너와의 연애는 정말 소설 같아. 우리 혹시 소설 속 주인공은 아닐까?” 상대방과의 결혼생활에 대한 환상을 가지고 있는 당신. 이 유형은 연애와 결혼 모두 200% 감정을 쏟는 편입니다. 머릿속에는 애인생각 뿐이고 그를 어떻게 하면 행복하고 감성적이게 해줄 수 있을까 골똘히 생각하는 편! 이러한 깊은 관계 탓에 결혼을 하면 의외로 안정적이게 살 수 있을 것이다. 연애에서 많이 휘둘렸지만 한번 안정적인 방향성을 찾으면 그야말로 결혼생활은 탄탄대로!",
      tag: "#영화속한장면 #난가끔눈물을흘려 #너무사랑스러워",
      status: {
        charm: 2,
        romance: 5,
        sexual: 3,
        drive: 3,
        energy: 2,
      },
      good: "entj",
      bad: "istj",
    },
    infj: {
      title: "한 번 빠지면 멈추지 못해",
      text:
        "처음부터 저돌적이고 적극적이진 않지만 한번 마음에 든 상대는 놓치지 않는다. 상대방을 온전히 이해하려는 포용적인 면모가 돋보여 이 사람을 짝사랑하는 사람 또한 많다고 하는데.. 겉보기엔 안정적인 연애와 결혼을 추구하지만 실상 결혼생활을 시작하면 어디로 튈지 모르는 탱탱볼 같은 결혼! 상대방을 들었다 놨다 하는 경향이 있어 항상 톡톡 튀는 결혼생활이 예상된다.",
      tag: "#넌내꺼야 #다이해해줄게 #들어와",
      status: {
        charm: 4,
        romance: 4,
        sexual: 4,
        drive: 4,
        energy: 5,
      },
      good: "entp",
      bad: "esfp",
    },
    intp: {
      title: "혼자가 더 편한데",
      text:
        "결혼보다는 연애, 연애보다는 혼자! 상대방에 대해 물밑작업을 굉장히 충실히 하는 당신! 이 사람은 결혼했을 때 양말을 반대로 벗어 놓을 것 같아, 아니 세탁기에 섬유 유연제를 덜 넣진 않을까? 이상한 부분에서 혼자 생각하고 결론지어 결혼에 골인하기까지 혼자만의 망상이 많은 타입! 이상형이 특이한 경우가 많아 혼자인 게 더 편하다고 생각하며 만약 결혼할 경우, 완벽하게 자신의 마음에 들지 않으면 결혼에 골인이 어려워 결혼하기 조금 .. 힘들 수도?",
      tag: "#재밌는사람이좋아 #프라이버시 #플라토닉러브",
      status: {
        charm: 3,
        romance: 2,
        sexual: 4,
        drive: 4,
        energy: 2,
      },
      good: "entj",
      bad: "isfj",
    },
    intj: {
      title: "겉과 속이 다른 츤데레",
      text:
        "딱히, 널 좋아하는 건 아닌데? 아니! 좋아해! 상대방에게 어떻게 다가가야 할지 몰라 안절부절하는 당신, 상대방이 리드해 주지 않는다면 앞에서 우물쭈물하다가 놓치는 경우가 다반사였을 텐데요. 의외로 불도저 같은 면모가 있어 맞으면 맞다 틀리면 틀린다고 명확한 판단을 내리기도 합니다. 나 너랑 결혼하고 싶은데 혹시 220문 220답 풀어줄 수 있어? 내가 그걸 보면 널 더 사랑할 수 있을 것 같아. 결혼까지 상대방에 대한 완벽한 파악을 마치고 싶어 하는 스타일! 스펙터클한 결혼생활이 기대가 됩니다.",
      tag: "#츤츤 #속마음을들키고싶지않아 #하지만알아줘",
      status: {
        charm: 3,
        romance: 4,
        sexual: 4,
        drive: 5,
        energy: 5,
      },
      good: "enfp",
      bad: "estp",
    },
    esfp: {
      title: "빨리 결혼할래?",
      text:
        "너! 내 애인이 돼라. 물 한 방울 묻히지 않고 행복하게 해줄게! 내 애인이야 예쁘고 잘생겼지? 동네 사람들 우리 애인 좀 봐주세요! 확성기 같은 면모가 있어 현재 연애에 충실하고 모든 연애에 있어서 결혼에 골인할 가능성이 매우 높다. 상대를 너무 좋아해서 머릿속엔 그 사람 생각만 둥둥 떠나니는 편입니다. 온갖 이벤트, 참견, 오지랖 모두 애인에게 해줄 거야! 댕댕이 같은 당신은 변덕도 심해 순식간에 식고 순식간에 빠지기도 해서 결혼도 번갯불에 콩 볶듯 진행될 것이라고 예상됩니다.",
      tag: "#날좀봐줘 #사람이좋아 #즐거운인생",
      status: {
        charm: 3,
        romance: 4,
        sexual: 4,
        drive: 2,
        energy: 5,
      },
      good: "istj",
      bad: "enfp",
    },
    esfj: {
      title: "나만의 따뜻한 파트너",
      text:
        "누구에게나 따듯하고 다정한 당신, 사회성이 좋아 주변에 이성이 끊이질 않는데.. 본인의 활동 영역에서 애인을 만나 결혼까지 골인할 가능성이 매우 높습니다! 현실적인 성향이 강해 상대와 내가 정말 잘 맞는다면 관계 발전 속도는 거의 KTX 급. 속도뿐만 아니라 안정성을 추구하려는 경향도 강해 결혼한다면 1등 신붓감, 1등 신랑감이라는 소리 꽤나 들을 것으로 예상됩니다. 오랜 친구와 연인이 되는 경우도 있지만 유혹에 약해 주변을 항시 살피는 자세 필요!",
      tag: "#따뜻한아메리카노 #매력덩어리 #일잘하는김대리",
      status: {
        charm: 3,
        romance: 4,
        sexual: 3,
        drive: 4,
        energy: 3,
      },
      good: "istp",
      bad: "infj",
    },
    estp: {
      title: "자유로운 인싸",
      text:
        "너도 좋고 얘도 좋고 다 좋아! 어, 커피 한잔하실래요 당신? 마음에 드는 상대방이 생기면 물불 안 가리고 달려드는 당신. 당신과의 만남은 누구에게나 항상 재밌고 유쾌하지만 감정 기복이 심해 상대방이 지칠 수 있습니다. 결혼생활에서도 이성과의 만남 때문에 상대방이 많이 힘들어하는 모습을 보일 수가 있는데요. 이를 꿋꿋이 참고 결혼 상대에게 신뢰를 준다면 당신의 결혼생활은 누구보다 순탄하게 흘러갈 것으로 예상되는걸요?",
      tag: "#인기쟁이 #술자리메이커 #자유가최고",
      status: {
        charm: 5,
        romance: 3,
        sexual: 3,
        drive: 4,
        energy: 4,
      },
      good: "isfj",
      bad: "enfj",
    },
    estj: {
      title: "담백하고 시원한",
      text:
        "씩씩하고 활기찬 당신, 마음에 드는 상대가 있다면 썸이고 연애고 다 건너뛰고 결혼에 골인할 수도 있다? 상대를 리드하는 경향이 있어 만약 결혼을 전제로 연애하거나 결혼에 성공한다면 매우 적극적이고 주도권을 잡고 있는 의외의 모습을 볼 수 있다. 매우 현실적인 성격이기 때문에 자신의 가치관에 반하는 상대방을 이해하기 어려울 수 있어 첫눈에 모든 마음을 줄 상대가 아니면 어영부영 만나는 스타일은 절대 아니다. 결혼에 골인한다면 가정의 기둥이 되어 솔선수범할 스타일!",
      tag: "#날따라와 #공격수 #현실인싸",
      status: {
        charm: 4,
        romance: 3,
        sexual: 5,
        drive: 5,
        energy: 5,
      },
      good: "intp",
      bad: "enfp",
    },
    enfp: {
      title: "너에게 모든 걸 줄게",
      text:
        "너한테 모든 걸 줄 테니까 너도 나한테 모든 걸 줘. 올인! 정말 상대방을 사랑하고 결혼까지 갈 생각이 있다면 불도저같이 결혼을 추진하는 편. 이성을 잘 파악하는 편이며 그들의 관심을 끄는데 탁월한 능력을 가지고 있습니다! 상대방을 편안하게 해주는 능력도 있어 진심으로 좋아하는 상대에게는 무한대로 애정을 쏟아붓곤 합니다. 하지만 마지막은 편안함을 추구함으로써 결혼을 누구보다도 원하는 유형이라고 할 수 있습니다. 당신 혹시, 현모양처?",
      tag: "#올인 #가즈아! #폭주기관차",
      status: {
        charm: 4,
        romance: 4,
        sexual: 3,
        drive: 5,
        energy: 5,
      },
      good: "intj",
      bad: "estj",
    },
    enfj: {
      title: "속은 여린 아이같은",
      text:
        "남녀노소에게 인기가 많지만 속은 아이 같아서 잘 챙겨주는 사람에게 마음이 기우는 당신, 연애 도중에는 상대방에게 미련 없이 잘해주지만 이별할 땐 뒤도 안 돌아보고 떠난다! 매력적인 면모가 강해 당신을 잊지 못해 연락하는 사람 대기줄이 끝도 없이 보여요! 관대하고 친근한 모습은 누구든 당신에게 끌림을 느끼게 합니다. 가볍게 만나다가도 진중하게 좋아하는 사람이 생기면 결혼을 생각하는 당신, 천생연분을 만난다면 순탄한 결혼생활이 예상되네요!",
      tag: "#순애보 #아가페사랑 #천생연분",
      status: {
        charm: 5,
        romance: 4,
        sexual: 3,
        drive: 2,
        energy: 3,
      },
      good: "infp",
      bad: "esfp",
    },
    entp: {
      title: "내 마음을 받아!",
      text:
        "이성에게 무한 애정공세를 펼칠 수 있는 당신의 능력, 과연 결혼까지 몇 명이나 만날 생각이신가요? 상대방이 마음에 들면 흙바닥에서라도 진주를 캐서 꺼내줄 당신. 엉뚱하고 독특한 매력이 있어 이런 면에 이성 꽤나 울렸겠는데요? 하지만 결혼을 빨리하거나 결혼에 대한 환상이 있는 경우가 많아 결혼생활이 순탄할 것으로 보이지는 않습니다. 그래도 상대방이랑 티키타카가 매우 잘 맞는 소울메이트 같은 사람과의 결혼이라면 당신의 결혼생활은 꽃밭이 될 거예요!",
      tag: "#스트레이트 #돌진하라! #에너자이저",
      status: {
        charm: 4,
        romance: 2,
        sexual: 4,
        drive: 4,
        energy: 5,
      },
      good: "infj",
      bad: "esfj",
    },
    entj: {
      title: "결혼까지 일사천리",
      text:
        "나랑 결혼해 줄래? 적극적이고 저돌적인 당신! 마음을 정하면 일사천리로 충실하게 진행하는 모습을 볼 수 있습니다. 자신의 부족한 점을 채워줄 사람을 원하며 연상과의 결혼 가능성이 높습니다. 하지만 상대를 아래로 보는 경향이 있어 말실수에 조심해야 합니다. 이 같은 경우만 제외한다면 쇼윈도 부부보다 더 쇼윈도 부부 같은 완벽한 결혼생활이 이루어질 수 있습니다. 누구보다 완벽한 결혼을 꿈꾸는 당신, 천생연분과의 결혼이 가장 적합합니다!",
      tag: "#진행시켜! #지휘관 #전략가",
      status: {
        charm: 3,
        romance: 4,
        sexual: 3,
        drive: 5,
        energy: 5,
      },
      good: "infp",
      bad: "estj",
    },
  },
};
const mutations = {
  ADD_INDEX(state) {
    state.quizIndex += 1;
    state.quizTransition = false;
  },
  DONE_TRANSITION() {
    state.quizTransition = true;
  },
  SET_SCORE(state, { target, score }) {
    state.score[target] += score;
  },
  RESET(state) {
    Object.keys(state.score).forEach((v) => {
      state.score[v] = 0;
    });
    state.quizIndex = 0;
    state.quizTransition = true;
  },
};

const actions = {};

export default new Vuex.Store({
  state,
  mutations,
  actions,
});
